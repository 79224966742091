<template>
<loading />
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';
import NotificationActionsMixin from '@/modules/mixins/notificationActions';

export default {
  name: 'NotificationRedirect',
  components: {
    Loading,
  },
  data() {
    return {
      notification: false,
    };
  },
  mixins: [NotificationActionsMixin],
  computed: {
    infoLoaded() {
      return this.$store.state.user.loaded;
    },
  },
  mounted() {
    if (this.infoLoaded) {
      this.handleNoti();
    }
  },
  methods: {
    handleNoti() {
      this.notification = this.$store.getters['user/notification'](this.$route.params.id);
      if (this.notification) {
        this.action();
      }

      if (this.$route.name === 'NotificationRedirect') {
        this.$router.push('/');
      }
    },
  },
  watch: {
    infoLoaded(newVal) {
      if (newVal) {
        this.handleNoti();
      }
    },
  },

};
</script>

<style>

</style>
